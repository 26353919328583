import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Wikander.io" lang="sv-se" />
    <section>
      <h1>wikander.io</h1>
    </section>
  </Layout>
)

export default IndexPage
